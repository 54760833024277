import NextLink from 'next/link'
import styled from 'styled-components'

import { theme } from 'styles/theme'

const { borderWidths, colors, space, transitions } = theme

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 14.625rem;
  min-width: 14.625rem;
  border: ${borderWidths['border-stroke-micro']} solid ${colors['neutral-border-default']};
  border-radius: ${space['spacing-sm']};

  > * {
    flex: 1;
  }
`

export const Image = styled.div`
  width: 100%;
  padding: ${space['spacing-sm']};
  position: relative;
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${space['spacing-sm']};
  gap: ${space['spacing-sm']};
`

export const Link = styled(NextLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${space['spacing-md']};
  background-color: ${colors['blue-background-strong-default']};
  color: ${colors['blue-text-strong']};
  border-bottom-left-radius: ${space['spacing-sm']};
  border-bottom-right-radius: ${space['spacing-sm']};
  transition: ${transitions.default};

  &:hover {
    background-color: ${colors['blue-background-strong-hover']};
  }
`
