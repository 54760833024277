import { FC, memo } from 'react'

import { Text } from '@cash-me/react-components'

import { ProductCard } from 'components/product-card'

import { ProductListProps } from './product-list.model'
import * as S from './styles'

export const ProductList: FC<ProductListProps> = memo(({ products }) => (
  <S.ProductsWrapper>
    {products.map(({ id, image, link, title, description, dataLayer }) => (
      <ProductCard key={id} image={image} link={link} dataLayer={dataLayer} priority>
        <Text as="h3" size="heading5" weight="medium" color="var(--colors-neutral-text-default)">
          {title}
        </Text>
        <Text size="body2" color="var(--colors-neutral-text-default)">
          {description}
        </Text>
      </ProductCard>
    ))}
  </S.ProductsWrapper>
))

ProductList.displayName = 'ProductList'
